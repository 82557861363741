<mat-accordion multi>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                What problem does Planet VG solve?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            Planet VG is a platform on which you can publish your promotions and stamp card programs.
            We provide all the necessary ingredients and tooling, which you can use to build your own successful
            promotion or stamp card program.
            There is no need to hire an expensive agency to build your promotion or stamp card program. With Planet VG’s
            user-friendly interface, you can easily create them yourself!
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                How long does my trial last?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            The trial period lasts 90 days. After that your chosen plan gets activated and you will be billed
            automatically; monthly or annually.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Can I change subscription plans?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            You can change your plans later on according to your needs.<br />Just be aware that when you change plan,
            certain features might be deactivated according to the chosen plan and your current settings.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                What is an&nbsp;<em>Active Stamp Card</em>&nbsp;?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            Depending on your subscription plan you can have a certain amount of active stamp card programs. Your active
            stamp card program is the stamp card that your customers see in their PlanetVG app and can collect stamps
            on.
            You can create as many stamp card programs as are included in your subscription plan.
            <br />
            <br />
            <em>For example:</em><br />
            Your subscription plan is “Starters”, you have 1 active stamp card. You create a stamp card which is valid
            from January until February. During this period no other stamp card programs can be active. When this stamp
            card program ends at the end of February, you can set a new stamp card program active.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                What is an&nbsp;<em>Active Promotion</em>&nbsp;?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            Depending on your subscription plan you can have a certain amount of active promotion programs. Your active
            promotion program is the promotion that your customers see in their PlanetVG app and from which they can
            claim its voucher.
            You can create as many promotion programs as are included in your subscription plan.
            <br />
            <br />
            <em>For example:</em><br />
            Your subscription plan is “Starters”, you have 1 active promotion. You create a promotion which is valid from
            Wednesday until Sunday. During this period no other promotion programs can be active. When this promotion
            program ends on Sunday, you can set a new promotion program active.
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                What is a&nbsp;<em>Store</em>&nbsp;?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            Depending on your subscription plan you can use your loyalty campaigns and promotions in a certain amount of
            your physical stores. The store is part of your company listing that your customers see in their PlanetVG
            app.<br />
            To help new customers easily determine whether your store is interesting to them, you can select what type
            of store you have:<br />
        </p>
        <ul>
            <li>Vegan</li>
            <li>Vegetarian</li>
            <li>Other</li>
        </ul>
        <p>
            And the following options:<br />
        </p>
        <ul>
            <li>Vegan Options</li>
            <li>Vegetarian Options</li>
            <li>Gluten Free Options</li>
            <li>Bio Organic</li>
            <li>Bio Dynamic</li>
            <li>Zero Waste</li>
            <li>Plastic Free</li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
        <mat-expansion-panel-header>
            <mat-panel-title>
                What is&nbsp;<em>Dashboard Reporting</em>&nbsp;?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            On your dashboard you will see the following:
        </p>
        <ul>
            <li>
                Quick overview of your active store locations, promotions and stamp cards.
            </li>
            <li>Total Members Chart. Overview of your members.</li>
            <li>
                Promotion Chart. Every active promotion has a chart displaying the following:
                <ul>
                    <li>Engagements: the amount of people that claimed the voucher.</li>
                    <li>
                        Issued Vouchers: the amount of people that received a voucher.
                    </li>
                    <li>
                        Redeemed Vouchers: the amount of people that redeemed the voucher.
                    </li>
                </ul>
                The ratio between <em>issued vouchers</em> and
                <em>redeemed vouchers</em> is important to measure the actual success
                of your promotion.
            </li>
            <li>
                Stamp Card Chart. Every active stamp card has a chart
                displaying the following:
                <ul>
                    <li>
                        Participants: the amount of people that is saving on a stamp card.
                    </li>
                    <li>
                        Issued Vouchers: the amount of people that received a voucher.
                    </li>
                    <li>
                        Redeemed Vouchers: the amount of people that redeemed the voucher.
                    </li>
                </ul>
                The ratio between <em>issued vouchers</em> and
                <em>redeemed vouchers</em> is important to measure the actual success
                of your stamp card.
            </li>
        </ul>
    </mat-expansion-panel>
</mat-accordion>
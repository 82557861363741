<div class="container" [formGroup]="registrationDetailsFormGroup">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header class="card-header">
      <mat-card-title>Register</mat-card-title>
      <mat-card-subtitle>Start saving today</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="container-card-content">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Ex. Fatima" formControlName="firstName" />
          <mat-error *ngIf="registrationDetailsFormGroup.get('firstName').hasError('required') 
        && registrationDetailsFormGroup.get('firstName').touched">
            <span>First name is required</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('firstName').hasError('maxlength') 
          && registrationDetailsFormGroup.get('firstName').touched">
            <span>First name can have a maximum length of {{ maxNameLength }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Ex. Doe" formControlName="lastName" />
          <mat-error *ngIf="registrationDetailsFormGroup.get('lastName').hasError('required') 
          && registrationDetailsFormGroup.get('lastName').touched">
            <span>Last name is required</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('lastName').hasError('maxlength') 
          && registrationDetailsFormGroup.get('lastName').touched">
            <span>Last name can have a maximum length of {{ maxNameLength }}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="Ex. Fatima@Doe.com" formControlName="email" />
          <mat-error *ngIf="registrationDetailsFormGroup.get('email').hasError('required') 
          && registrationDetailsFormGroup.get('email').touched">
            <span>Email is required</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('email').hasError('pattern') 
          && registrationDetailsFormGroup.get('email').touched">
            <span>Not a valid email is provided</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('email').errors?.accountEmailTaken 
          && registrationDetailsFormGroup.get('email').touched">
            <span>This email is already used</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('email').hasError('maxlength') 
          && registrationDetailsFormGroup.get('email').touched">
            <span>Email can have a maximum length of {{ maxEmailLength }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password" matInput placeholder="Please generate a password using a password manager"
            formControlName="password" />
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').hasError('required') 
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password is required</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').errors?.hasNumber
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password does not contain at least one number</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').errors?.hasCapitalCase
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password does not contain at least one uppercase letter</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').errors?.hasSmallCase
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password does not contain at least one lowercase letter</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').errors?.hasSpecialCase
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password does not contain at least one special character</span>
          </mat-error>
          <mat-error *ngIf="registrationDetailsFormGroup.get('password').hasError('minlength')
          && registrationDetailsFormGroup.get('password').touched">
            <span>Password does not contain at least 16 characters</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Confirm password</mat-label>
          <input type="password" matInput placeholder="Confirm your password" formControlName="confirmPassword" />
          <mat-error *ngIf="
            registrationDetailsFormGroup.get('confirmPassword').hasError('required') &&
            registrationDetailsFormGroup.get('confirmPassword').touched
          ">
            <span>Confirm password is required</span>
          </mat-error>
          <mat-error *ngIf="
            registrationDetailsFormGroup
              .get('confirmPassword').errors?.mustMatch &&
            registrationDetailsFormGroup.get('confirmPassword').touched
          ">
            <span>Passwords do not match</span>
          </mat-error>
        </mat-form-field>
        <p>
          By creating an account, you agree to Planet VG's <a href="/user-terms" target="_blank">User Terms &
            Conditions</a>, <a href="/partner-terms" target="_blank">Partner Terms & Conditions</a> and <a
            href="/privacy-policy" target="_blank">Privacy Policy</a>.
        </p>
      </div>
    </mat-card-content>
    <mat-card-actions class="card-actions">
      <button mat-raised-button color="primary" (click)="register()" [disabled]="!registrationDetailsFormGroup.valid">
        <mat-icon>app_registration</mat-icon>
        Sign Up
      </button>
    </mat-card-actions>
  </mat-card>
</div>
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

export interface PlanComparison {
  featureName: string,
  basicValue: string,
  standardValue: string,
  emphasis : boolean
}

const ELEMENT_DATA : PlanComparison[] = [
  {featureName: "Active Stamp Cards", basicValue: "1", standardValue: "3", emphasis: false},
  {featureName: "Active Promotions", basicValue: "1", standardValue: "3", emphasis: false},
  {featureName: "Stores", basicValue: "1", standardValue: "1", emphasis: false},
  {featureName: "Members", basicValue: "Unlimited", standardValue: "Unlimited", emphasis: false},  
  {featureName: "Dashboard Reporting", basicValue: "Yes", standardValue: "Yes", emphasis: false},
  {featureName: "24/7 Email Support", basicValue: "Yes", standardValue: "Yes", emphasis: false},
  {featureName: "Set-up fees", basicValue: "No", standardValue: "No", emphasis: false},
  {featureName: "Multiple Stores", basicValue: "In the making", standardValue: "In the making", emphasis: false},
  {featureName: "Gamification", basicValue: "-", standardValue: "In the making", emphasis: true},
  {featureName: "Customer Segmentation", basicValue: "-", standardValue: "In the making", emphasis: true},
  {featureName: "Birthday & Veganniversary Club", basicValue: "-", standardValue: "In the making", emphasis: true},
];

@Component({
  selector: 'app-pricing-compare-plans',
  templateUrl: './pricing-compare-plans.component.html',
  styleUrls: ['./pricing-compare-plans.component.scss']
})
export class PricingComparePlansComponent implements OnInit {
  displayedColumns: string[] = ['featureName', 'basic', 'standard'];
  dataSource = ELEMENT_DATA;

  private _isXLarge : boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(private readonly responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  isXLarge() : boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }
}